import React from 'react';
import './MainScreen.css';

export default function MainScreen() {
  return (
    <div className='main-container'>
      <div className='body'>
        <div className='div'>
          <div className='main'>
            <div className='div-1'>
              <div className='div-2'>
                <div className='ul'>
                  <div className='li'>
                    <div className='div-3'>
                      <span className='child-1'>Child 1</span>
                    </div>
                  </div>
                  <div className='li-4'>
                    <div className='div-5'>
                      <span className='child-2'>Child 2</span>
                    </div>
                  </div>
                  <div className='li-6'>
                    <div className='main-div'>
                      <span className='child-span'>Child 3</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='sub-div'>
              <div className='sub-div-7'>
                <div className='sub-div-8'>
                  <div className='sub-div-9' />
                  <div className='sub-div-a'>
                    <div className='sub-div-b'>
                      <div className='image-div' />
                      <div className='sub-div-c'>
                        <span className='due-span'>Due: Today</span>
                      </div>
                    </div>
                    <span className='clean-room-span'>Clean Room</span>
                    <button className='button-span'>
                      <span className='points-span'>50 pts</span>
                    </button>
                  </div>
                  <button className='button'>
                    <span className='confirm-span'>Confirm</span>
                  </button>
                </div>
              </div>
              <div className='div-d'>
                <div className='div-e'>
                  <div className='div-f'>
                    <div className='div-10'>
                      <div className='image-div-11' />
                      <div className='div-12'>
                        <span className='due-tomorrow-span'>Due: Tomorrow</span>
                      </div>
                    </div>
                    <span className='do-homework-span'>Do Homework</span>
                    <button className='span'>
                      <span className='pts-span'>30 pts</span>
                    </button>
                  </div>
                  <button className='button-13'>
                    <span className='confirm-span-14'>Confirm</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className='nav'>
            <div className='main-div-15'>
              <div className='btn'>
                <div className='svg-container'>
                  <div className='frame'>
                    <div className='vector' />
                  </div>
                </div>
                <span className='settings'>Settings</span>
              </div>
              <div className='btn-16'>
                <div className='svg-container-17'>
                  <div className='frame-18'>
                    <div className='vector-19' />
                  </div>
                </div>
                <span className='family'>Family</span>
              </div>
              <div className='btn-1a'>
                <div className='svg'>
                  <div className='frame-1b'>
                    <div className='vector-1c' />
                  </div>
                </div>
                <span className='home'>Home</span>
              </div>
            </div>
          </div>
          <div className='div-1d'>
            <div className='div-1e'>
              <span className='parent'>Parent</span>
            </div>
            <div className='div-1f'>
              <div className='img' />
            </div>
          </div>
          <span className='john-doe'>John Doe</span>
          <div className='button-20'>
            <div className='svg-21'>
              <div className='frame-22'>
                <div className='vector-23' />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
