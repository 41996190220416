import axios from 'axios';

//const API_URL = 'https://node02.msk.prototypes.ventures:50105/api/';
const API_URL = 'https://tap.kidcash.guru/api';

export const authenticateUser = async () => {
  const tg = window.Telegram.WebApp;
  const initData = tg.initData;
  console.log("initData");
  console.log(initData);
  const initDataUnsafe = tg.initDataUnsafe;

  try {


    //fetch('https://rip.prototypes.ventures/api/red/func/users/check/', {
    //  method: 'GET',
    //  headers: {
    //    Authorization: `tma ${initDataRaw}`,
    //  },
    //});
    
    const response = await axios.get(`${API_URL}/auth/telegram/`, {
      headers: {
        'Authorization': `tma ${initData}`
      }
    });


    //const response = await axios.post(`${API_URL}/auth/telegram/`, {
    //  initData,
    //});

    // Сохраняем токен в localStorage или context
    const token = response.data.access;
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    return response.data;
  } catch (error) {
    console.error('Authentication error:', error);
    throw error;
  }
};